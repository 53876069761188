/**=====================
 custom CSS Start
==========================**/
// you can add your custom css here
@mixin breakpoint($size) {
    @media (max-width: $size) {
        @content;
    }
}

.pagination-wrapper {
    .pagination {
        display: flex;
        justify-content: flex-end;
        gap: 4px;

        li {
            a {
                height: 30px;
                border-color: var(--light-bg);
                border-radius: 5px;
                color: var(--theme-deafult);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.8125rem;
            }

            &.active {
                a {
                    background-color: var(--theme-deafult);
                    color: var(--white);
                }
            }
        }
    }
}

//form control
input[type=text],
input[type=email],
textarea {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: transparent;
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus,
    &:focus-within,
    &:active {
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
}

.ad-search-form {
    &.form-group {
        position: relative;
        margin-bottom: 0;

        .form-control {
            height: 36px !important;
        }

        i {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 14px;
            color: $light-gray;
        }
    }
}

.error {
    color: var(--status-cancelled);
    font-size: 13px;
}

.btn-default {
    display: flex;
    background-color: var(--theme-deafult);
    height: 36px;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    font-weight: 600;
    color: var(--white);
}

//model popup
.modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ad-model-btn {
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 4px;
        padding: 10px;
        cursor: pointer;
    }
}

.modal-dialog-scrollable .modal-body {
    overflow-y: scroll;
    max-height: calc(100vh - 232px);
}

.ad-mordern-breadcrumb {
    padding: 15px 12px;
}

.ad-breadcrumb-title {
    padding: 6px 0;
}

.btn-custom-primary {
    background-color: var(--theme-deafult);
    color: var(--white);
    height: 36px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 0 8px;
    font-weight: 600;
    transition: all 0.3s ease;

    &:hover {
        background-color: var(--theme-deafult);
        color: var(--white);
    }

    svg {
        width: 16px;
        height: 16px;
        fill: var(--white);
    }
}

dp-date-picker {
    width: 100%;
}

.form-group {
    margin-bottom: 1rem;

    label {
        color: var(--chart-text-color);
        font-weight: 300;
        letter-spacing: normal;
        font-size: 13px;
        margin-bottom: 2px;
    }
}

.custom-select {
    border-radius: 10px;
}

.ng-select {
    .ng-select-container {
        height: calc(2.4em + .75rem + 2px) !important;
        width: 100% !important;
        border-radius: 10px;
        border: 1px solid #e7e8f3;
        border-radius: 10px;
        background-color: var(--white);
        font-size: 14px;
    }
}

input[type=text],
input[type=email],
textarea {
    height: calc(2.4em + .75rem + 2px) !important;
    width: 100% !important;
    padding: .5rem 1rem;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 1px solid #e7e8f3;
    border-radius: 10px;
    background-color: var(--white);
    font-size: 14px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 11px;
    left: 12px;
}

// Filter module

.customizer-contain {
    position: fixed;
    height: 100%;
    top: 0;
    box-shadow: none;
    width: 868px;
    right: -868px;
    background-color: var(--white);
    z-index: 9;
    transition: all 0.3s ease;

    &.open {
        right: 0px;
        transition: all 0.3s ease;
        box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);

        .icon-close-icon {
            left: -10px;

            @include breakpoint(991px) {
                left: 20px;
            }
        }

        @include breakpoint(991px) {
            width: 100%;
        }
    }

    li {
        position: relative;

        &.active {
            box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
        }
    }

    h6 {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 10px;
    }

    .customizer-header {
        background-color: var(--theme-deafult);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid $light-color;

        @include breakpoint(991px) {
            padding: 15px 20px 15px 50px;
        }

        @include breakpoint(480px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .fiter-title {
            h5 {
                color: var(--white);
                margin-bottom: 0;
                padding-left: 10px;

                @include breakpoint(480px) {
                    width: 100%;
                    justify-content: flex-start;
                }
            }
        }

        .icon-close-icon {
            position: absolute;
            background-color: var(--white);
            box-shadow: 0 2px 8px 0 #63636333;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            z-index: 1;
            right: auto;
            transition: .4s ease-in-out;
            cursor: pointer;
        }

        .button-group-cotainer {
            @include breakpoint(480px) {
                width: 100%;
                justify-content: flex-end;
            }

            .btn {
                position: relative;
                background-color: var(--white);
                border: 3px solid var(--theme-deafult);
                padding: 0;
                width: 42px;
                height: 42px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: -10px;
                transition: .5s ease-in-out;
                z-index: 0;
            }

            .btn.btn-auto-group {
                width: auto;
                border-radius: 25px;
                padding: 0 15px;
                font-weight: 500;
            }
        }

        .icon-close {
            position: absolute;
            font-size: 18px;
            cursor: pointer;
            font-weight: 900;
            right: 30px;
            color: var(--theme-deafult);
            margin-top: 7px;
        }

        h5 {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .customizer-body {
        padding: 15px 25px;
        //max-height: calc(100vh - 160px);

        @include breakpoint(667px) {
            overflow-y: scroll;
            max-height: calc(100vh - 100px);
        }

        .unlimited-color-layout {
            display: flex;
            align-items: center;

            .btn {
                font-size: 15px;
                padding: 4px 20px;
            }

            input {
                border: 1px solid $gray-60;
                height: 35px;
                width: 35px;
                border-radius: 5px;
                background-color: $white;
                margin-right: 5px;
            }
        }

        .layout-grid {
            &.customizer-mix {
                .color-layout {
                    height: 70px;
                    width: 80px;
                    border: 1px solid #f6f7fb;
                    border-radius: 5px;
                }
            }
        }

        .tab-pane {
            >h6 {
                &:first-child {
                    padding-top: 0;
                    margin-top: 0;
                }
            }
        }

        li {
            cursor: pointer;
            opacity: 0.9;

            &.active {
                opacity: 1;
            }
        }

        .color-layout {
            height: 35px;
            width: 35px;
            border-radius: 6px;
            display: inline-block;
            margin-right: 3px;
            border: 1px solid #b8b8b8;
            padding: 3px;

            >div {
                background-color: #00b3fe;
                height: 100%;
                width: 100%;
                border-radius: 5px;
            }

            &[data-attr="color-2"] {
                div {
                    background-color: #4831D4;
                }
            }

            &[data-attr="color-3"] {
                div {
                    background-color: #d64dcf;
                }
            }

            &[data-attr="color-4"] {
                div {
                    background-color: #4c2fbf;
                }
            }

            &[data-attr="color-5"] {
                div {
                    background-color: #7c4dff;
                }
            }

            &[data-attr="color-6"] {
                div {
                    background-color: #3949ab;
                }
            }
        }

        .dark {
            >li {
                background-color: #2f3c4e;
            }
        }

        .main-layout {
            >li {
                height: 65px;
                width: 85px;
                border: 1px solid $light-color;
                border-radius: 10px;
                display: inline-block;

                &+li {
                    margin-left: 7px;
                }
            }

            .box-layout {
                .body {
                    .badge {
                        left: -14px;
                    }
                }
            }
        }

        .sidebar-type,
        .sidebar-setting {
            >li {
                height: 70px;
                width: 85px;
                border: 1px solid $light-color;
                border-radius: 5px;
                display: inline-block;

                &+li {
                    margin-left: 7px;
                }
            }
        }

        .sidebar-type {
            li {
                &[data-attr="normal-sidebar"] {
                    .body {
                        ul {
                            li {

                                &.bg-dark,
                                &.sidebar {
                                    width: 100%;
                                    height: 10px;
                                    display: block;
                                }

                                &.bg-light,
                                &.body {
                                    height: calc(100% - 10px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// dark only
body {
    &.dark-only {

        input[type=text]::placeholder,
        input[type=email]::placeholder,
        textarea::placeholder,
        .form-control::placeholder {
            color: $dark-gray;
        }

        .modal-content {
            .modal-body {

                .form-control::placeholder,
                input[type=text]::placeholder,
                input[type=email]::placeholder,
                textarea::placeholder {
                    color: $dark-gray;
                }
            }
        }

        .ng-select .ng-select-container {
            color: $dark-gray;
        }

        .ng-select .ng-select-container .ng-value-container .ng-value {
            color: $dark-gray;
            position: relative;
            z-index: 1;
        }

        .ng-select .ng-select-container .ng-value-container .ng-placeholder {
            background-color: transparent;
            color: $dark-gray;
            z-index: 1;
        }

        .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table th,
        .page-wrapper .page-body-wrapper .page-body .card:not(.email-body) .table td,
        .page-wrapper .page-body-wrapper .page-body .card:not(.email-body),
        .page-wrapper .page-body-wrapper .page-body {
            background-color: #252831;
        }

    }
}

// scrollbar
::-webkit-scrollbar {
    width: 5px;
}



// Date time picker
dp-date-picker {
    width: 100%;

    &.dp-material {
        .dp-picker-input {
            box-sizing: border-box !important;
            width: 100% !important;
            font-size: 14px !important;
            color: $theme-body-font-color;
            outline: none;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            border: 1px solid $light-semi-gray;
            border-radius: 10px;
            background-color: $white;
            height: calc(2.4em + 0.75rem + 2px) !important;
            padding: 0.5rem 1rem;
        }
    }

    .dp-material {
        &.dp-popup {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
            border-left: none;
            border-right: none;
            border-bottom: none;
            z-index: 9999;
            white-space: nowrap;
        }
    }

    dp-calendar-nav {
        &.dp-material {
            .dp-calendar-nav-container {
                height: 40px;
                border: none;
            }
        }
    }

    dp-day-calendar {
        &.dp-material {
            .dp-calendar-wrapper {
                border: none;
                padding: 15px;
            }

            .dp-calendar-weekday {
                color: $black;
                border: none;
                font-size: 11px;
            }

            .dp-current-day {
                border: none;
                background-color: $success-color;
            }
        }

        .dp-calendar-day {
            font-size: 12px;
        }
    }

    dp-day-time-calendar {
        &.dp-material {
            dp-time-select {
                border: none;
                border-top: 1px dashed #dcdcdc;
            }
        }
    }

    dp-month-calendar {
        &.dp-material {
            .dp-calendar-month {
                font-size: 12px;
                width: 45px;
                height: 45px;
                padding: 15px 0;
            }

            .dp-current-month {
                border: none !important;
                background-color: $success-color;
            }
        }
    }
}

dp-day-calendar .dp-weekdays {
    font-size: 13px !important;
    margin-bottom: 3px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 13px !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    margin-top: 2px !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    margin-top: 5px !important;
}