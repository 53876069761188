/*! -----------------------------------------------------------------------------------

    Template Name: Adloggs  Admin
    Template URI: https://adloggs.com/
    Author: Adloggs

-----------------------------------------------------------------------------------
 */
// *** utils ***
@import "../scss/utils/variables";

:root {
  --theme-deafult: #25274e;
  --theme-secondary: #52d5f2;
  --light-background: rgba(242, 243, 247, 0.7);
  --body-font-color: #2f2f3b;
  --chart-border: #ecf3fa;
  --recent-chart-bg: #fcfcfd;
  --light-bg: #f6f7f9;
  --white: #fff;
  --light2: #f5f6f9;
  --sidebar-border: rgba(0, 0, 0, 0.1);
  --chart-text-color: rgba(82, 82, 108, 0.8);
  --recent-dashed-border: rgba(82, 82, 108, 0.3);
  --chart-dashed-border: rgba(82, 82, 108, 0.2);
  --chart-progress-light: rgba(82, 82, 108, 0.1);
  --recent-box-bg: #f2f4f7;
  --recent-border: rgba(97, 101, 122, 0.25);
  --course-light-btn: #f2f3f7;
  --course-bg: #f7f7f7;
  --balance-profie-bg: #e0dfef;
  --view-grid-bg: #ecf3fa80;
  --view-border-marker: #cfcdfc;
  
  //adloggs

  --active-status: #7366FF;
  --active-odd-status: #7fd966 ;
  --active-info-status: #20C3FA;
//order status
  --status-pending: #fe8a7d;
  --status-pending-bg: rgba(254, 138, 125, 0.05);
  --status-assigned: #ff8634;
  --status-assigned-bg: rgba(255, 134, 52, 0.05);
  --status-picked-up: #20C3FA;
  --status-picked-up-bg: rgba(32, 195, 250, 0.05);
  --status-delivered: #74dd65;
  --status-delivered-bg: rgba(116, 221, 101, 0.1);
  --status-cancelled: #ee3861;
  --status-cancelled-bg: rgba(238, 56, 97, 0.05);
  --status-deleted: #f10a85;
  --status-deleted--bg: rgba(241, 10, 133, 0.05);
  --status-arrived: #a927f9;
  --status-arrived--bg: rgba(169, 39, 249, 0.05);
  --status-undelivered: #f7ac2c;
  --status-undelivered--bg: rgba(247, 172, 44, 0.05);
  --status-arrived-at-pickup: #5337e7;
  --status-arrived-at-pickup-bg: rgba(83, 55, 231, 0.05);
  --status-out-for-delivery: #0da74b;
  --status-out-for-delivery-bg: rgba(13, 167, 75, 0.05);

  --fc-button-bg-color: var(--theme-deafult);
  --fc-button-active-bg-color: var(--theme-deafult);
  --fc-button-active-border-color: var(--theme-deafult);
  --fc-button-border-color: var(--theme-deafult);
  --fc-button-hover-bg-color: var(--theme-deafult);
  --fc-button-hover-border-color: var(--theme-deafult);
}

body[class="dark-only"] {
  --theme-deafult: #74dd65;
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
  --bs-tertiary-bg: #1d1e26;
  --bs-border-color: #374558;
  --bs-modal-footer-border-color: #374558;
  --bs-secondary-bg: #1d1e26;
}
body[class="rtl dark-only"] {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
  --bs-tertiary-bg: #1d1e26;
  --bs-border-color: #374558;
  --bs-modal-footer-border-color: #374558;
  --bs-secondary-bg: #1d1e26;
}
body.dark-only.box-layout {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
  --bs-tertiary-bg: #1d1e26;
  --bs-border-color: #374558;
  --bs-modal-footer-border-color: #374558;
  --bs-secondary-bg: #1d1e26;
}

/* ---------------------
	*** Base ***
-----------------------*/
@import "base/reset";
@import "base/typography";

//  *** components ***
@import "components/alert.scss";

//	*** pages ***
@import "pages/language.scss";
@import "pages/login.scss";

/* ---------------------
	*** themes ***
-----------------------*/
@import "themes/dark.scss";
//@import "themes/update.scss";

// *** layout ***
@import "layout/footer.scss";
@import "layout/header.scss";
@import "layout/search.scss";
@import "layout/sidebar.scss";
@import "layout/rtl.scss";
@import "layout/box-layout.scss";

// *** custom
@import "themes/custom";
